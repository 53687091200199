import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    planos: [],
    currentPlan: null
};

export const planSlice = createSlice({
    name: 'plan',
    initialState,
    reducers: {
        setPlanos: (state, action) => {
            state.planos = action.payload;
            state.currentPlan = action.payload[0] || null;
        },
        incrementPlan: (state) => {
            if (state.currentPlan) {
                const currentIndex = state.planos.findIndex(p => p.id === state.currentPlan.id);
                state.currentPlan = state.planos[Math.min(currentIndex + 1, state.planos.length - 1)] || state.currentPlan;
            }
        },
        decrementPlan: (state) => {
            if (state.currentPlan) {
                const currentIndex = state.planos.findIndex(p => p.id === state.currentPlan.id);
                state.currentPlan = state.planos[Math.max(currentIndex - 1, 0)] || state.currentPlan;
            }
        }
    },
});

export const { setPlanos, incrementPlan, decrementPlan } = planSlice.actions;

export default planSlice.reducer;
